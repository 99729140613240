<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent
          class="py-8"
          title="IP Cap Sensitivity Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="IP Cap Sensitivity Test Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/CapSensitivityDetailed-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/CapSensitivityDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The BATF and DoD use cap sensitiveness to distinguish between a
              high and low explosive. If the material fails the #8 cap test,
              then the BATF requires use of the high explosion table for storage
              siting and the DoD identifies the material as a 1.1 material in
              its unpackaged form. In manufacturing operations this type of
              material should be considered to have a mass explosion hazard. The
              #8 cap test, as used by the DOT or DoD is a good test for this
              purpose.
            </p>
            <TitleComponent class="py-8" title="Equivalence" size="medium" />
            <p class="p-light">
              This test is equivalent to
              <router-link class="link" to="/cap-sensitivity-test"
                >Test 5 (a)</router-link
              >
              in the UN Manual of Tests and Criteria.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              A witness plate or a cylinder of lead can be used to determine the
              test outcome
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Confining medium</td>
                      <td>Provide minimal confinement of the substance</td>
                      <td></td>
                      <td>Cardboard tube</td>
                    </tr>
                    <tr>
                      <td>Tube diameter (80mm min)</td>
                      <td>
                        Sufficient diameter to be above the critical diameter of
                        substances readily detonated by a standard detonator
                      </td>
                      <td></td>
                      <td>80mm diameter minimum, 160mm length</td>
                    </tr>
                    <tr>
                      <td>Ignition stimulus (standard detonator)</td>
                      <td>Provide a standard intense mechanical stimulus</td>
                      <td></td>
                      <td>Standard No. 8 detonator</td>
                    </tr>
                    <tr>
                      <td>Detonator placement</td>
                      <td>
                        Ensure damage to witness plate is not a result of the
                        detonator
                      </td>
                      <td></td>
                      <td>
                        Inserted centrally in the top to a depth equal to its
                        length
                      </td>
                    </tr>
                    <tr>
                      <td>Witness plate or lead cylinder specifications</td>
                      <td>Evidence of reaction type</td>
                      <td></td>
                      <td>
                        1.0mm thick, 160 x 160mm steel plate or 51mm diameter,
                        102mm long lead cylinder
                      </td>
                    </tr>
                    <tr>
                      <td>Steel ring</td>
                      <td>
                        Provide an air gap beneath the witness plate, allowing
                        for deformation of the witness plate
                      </td>
                      <td></td>
                      <td>50mm height, 100mm ID, 3.5mm wall</td>
                    </tr>
                    <tr>
                      <td>Substance density</td>
                      <td>
                        Determine whether the substance in its in-process form
                        is sensitive to the test stimulus
                      </td>
                      <td></td>
                      <td>
                        Substance to be tested as near as possible to the
                        in-process density
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>
                      Damage to the witness plate or compression of the lead
                      cylinder
                    </td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Tear or penetration of the witness plate. Compression of
                      the lead cylinder of 3.2 mm or more.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Damage to Witness Plate (-) Example Photo"
              size="small"
            />
            <a
              :href="require('../../assets/IPSeries/nogoipcap-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/nogoipcap-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Damage to Witness Plate (-) Example Video"
              size="small"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130320?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130320"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Damage to Witness Plate (+) Example Photo"
              size="small"
            />
            <a
              :href="require('../../assets/IPSeries/goipcap-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/goipcap-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Damage to Witness Plate (+) Example Video"
              size="small"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130317?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130317"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
